import {
  inject,
  computedFrom
} from 'aurelia-framework';
import {
  DialogController
} from 'aurelia-dialog';

import { DateTimeUtils } from '@fonix/web-utils';
import moment from 'moment';

import './advanced-settings-dialog.scss';

@inject(DialogController)
export class AdvancedSettingsDialog {

  constructor(_DialogController) {
    this.dialogController = _DialogController;

    this.timelineDownloadRange = {
      startDateTime: null,
      endDateTime: null,
    };

    this.isOfflineDownload = false;
    this.channels = null;
    this.channelsSelected = true;

    this.defaultDate = null;
    this.timeFrom = null;
    this.timeTo = null;
    this.range = null;
    this.timelineMin = null;
    this.timelineMax = null;

    this.onTimeChanged = this.onTimeChanged.bind(this);

  }

  activate(model) {
    if (model) {
      if (model.isOfflineDownload) {
        this.isOfflineDownload = model.isOfflineDownload;
        this.channels = model.channels;

        this.channels.forEach((e) => e.id == 1 ? e.selected = true : e.selected = false);
      }

      if (model.timelineDownloadRange) {
        const temporaryStoredTime = {
          startDateTime: model.timelineDownloadRange.startDateTime,
          endDateTime: model.timelineDownloadRange.endDateTime,
          customTime: false,
          rangedTime: moment(model.timelineDownloadRange.endDateTime).diff(moment(model.timelineDownloadRange.startDateTime), 'seconds')
        }
        sessionStorage.setItem('temporaryStoredTime', JSON.stringify(temporaryStoredTime));
        this.timelineDownloadRange = model.timelineDownloadRange;
        this.timeFrom = moment(this.timelineDownloadRange.startDateTime).format('HH:mm:ss');
        this.timeTo = moment(this.timelineDownloadRange.endDateTime).format('HH:mm:ss');
        this.setRange(moment(this.timelineDownloadRange.endDateTime), moment(this.timelineDownloadRange.startDateTime));
      }

      if (model.timelineBorders) {
        this.defaultDate = model.timelineBorders.dtfrom;
        this.timelineMin = moment(model.timelineBorders.dtfrom).format('HH:mm:ss');
        this.timelineMax = moment(model.timelineBorders.dtto).format('HH:mm:ss');
      }
    }
  }

  onDateFilterChanged(dtfrom, dtto) {
    sessionStorage.setItem('offlineDtFrom', JSON.stringify(dtfrom));
    sessionStorage.setItem('offlineDtto', JSON.stringify(dtto));
  }

  checkSelectedDate() {
    const REFERENCE = moment(new Date());
    const TODAY = REFERENCE.clone().startOf('day');
    const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

    if (moment(this.defaultDate).isSame(TODAY, 'd')) {
      return 0;
    }
    if (moment(this.defaultDate).isSame(YESTERDAY, 'd')) {
      return 1;
    }
    return 2;
  }

  setRange(end, start) {
    this.range = moment.utc(moment(end, "DD/MM/YYYY HH:mm:ss").diff(moment(start, "DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
  }

  onTimeChanged(time, label) {

    const parsedTime = (time) => {
      return DateTimeUtils.parseTime(time);
    }

    if (label === 'start' && this.timeFrom > this.timeTo) {
      this.timeTo = moment(parsedTime(this.timeFrom)).add(10, 'seconds').format('HH:mm:ss');
    } else if (label === 'end' && this.timeTo < this.timeFrom) {
      this.timeFrom = moment(parsedTime(this.timeTo)).subtract(10, 'seconds').format('HH:mm:ss');
    }

    let _timeFrom = parsedTime(this.timeFrom);
    let _timeTo = parsedTime(this.timeTo);

    this.timelineDownloadRange.startDateTime = moment(this.timelineDownloadRange.startDateTime).set({ "hour": _timeFrom.getHours(), "minute": _timeFrom.getMinutes(), "second": _timeFrom.getSeconds() });
    this.timelineDownloadRange.endDateTime = moment(this.timelineDownloadRange.endDateTime).set({ "hour": _timeTo.getHours(), "minute": _timeTo.getMinutes(), "second": _timeTo.getSeconds() });
    this.timelineDownloadRange.rangedTime = moment(this.timelineDownloadRange.endDateTime).diff(moment(this.timelineDownloadRange.startDateTime), 'seconds');

    this.setRange(moment(this.timelineDownloadRange.endDateTime), moment(this.timelineDownloadRange.startDateTime));
  }

  validateChannels() {
    this.timelineDownloadRange.channels = this.channels.filter(c => c.selected);
  }

  onReset() {
    this.dialogController.cancel('reset');
  }

  onCancel() {
    let time = JSON.parse(sessionStorage.temporaryStoredTime);
    this.timelineDownloadRange.startDateTime = time.startDateTime;
    this.timelineDownloadRange.endDateTime = time.endDateTime;
    this.timelineDownloadRange.rangedTime = time.rangedTime;
    this.dialogController.cancel('cancel');
  }

  onConfirm() {
    if (this.isOfflineDownload) {
      this.timelineDownloadRange.channels = this.channels.filter(c => c.selected);
      this.timelineDownloadRange.startDateTime = JSON.parse(sessionStorage.getItem('offlineDtFrom'))
      this.timelineDownloadRange.endDateTime = JSON.parse(sessionStorage.getItem('offlineDtto'))
      this.onTimeChanged(this.timeFrom, 'start');
      this.onTimeChanged(this.timeTo, 'end');
    }
    this.dialogController.ok(this.timelineDownloadRange);
  }

  unbind() {
    this.timeFrom = null;
    this.timeTo = null;
    this.range = null;
  }
}
