import {
  computedFrom,
  inject,
  bindable
} from 'aurelia-framework';
import {
  Router
} from 'aurelia-router';
import dashboardService from 'services/api/dashboardService';
import {
  DateTimeUtils as DTU
} from '@fonix/web-utils';

@inject(Router)
export class FleetStatusCard {

  constructor(router) {
    this.dashboardService = dashboardService;
    this.router = router;

    this.loading = true;
    this.error = null;
    this.card = {};

    this.options = {
      donut: true,
      donutWidth: '50%',
      donutSolid: true,
      startAngle: 0,
      showLabel: true,
      ignoreEmptyValues: true
    };

    this.responsiveOptions = [
      ['screen and (max-width: 600px)', {
        donutWidth: '50%',
        height: 180
      }],
      ['screen and (min-width: 601px)', {
        donutWidth: '50%',
        height: 190
      }],
      ['screen and (min-width: 993px)', {
        donutWidth: 50,
        height: 210
      }],
      ['screen and (min-width: 1200px)', {
        donutWidth: 50,
        height: 220
      }]
    ]

    this.viewReport = this.viewReport.bind(this)
  }

  activate(data) {
    if (data.card && data.card.id) {
      this.card.id = data.card.id
    }

    this.generateReport = (data.generateReport) ? data.generateReport.bind(this) : null;
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.dashboardService.get(this.card.id).then(kpi => {
      this.card = kpi;
      this.loading = false;
    }).catch(err => {
      this.error = err.message
      this.loading = false;
    });
  }

  @computedFrom('card')
  get adaptedData() {
    let chartData = {};
    chartData.series = []

    if (this.card && this.card.data && this.card.data.length) {
      if (this.card.data.find(d => d.label == 'inUse')) {
        chartData.series.push(this.card.data.find(d => d.label == 'inUse').value)
      }
      if (this.card.data.find(d => d.label == 'parked')) {
        chartData.series.push(this.card.data.find(d => d.label == 'parked').value)
      }
    }

    return chartData
  }

  viewReport() {
    if (this.generateReport) {
      let settings = {
        reportId: 2, //current-status
        preferences: {
          pagePerContext: false
        },
        customSettings: {
          excludeEmpty: false
        },
        contextType: 'assets',
        context: [-1],
        timeZone: DTU.detectTZ()
      }

      this.generateReport(settings)
    }
  }
}
