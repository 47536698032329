import store from './store';
import * as Mutations from './mutations';

function changedDeviceId(payload) {
  store.dispatch(Mutations.changedDeviceId, payload);
}

function timelineDiffHours(payload) {
  store.dispatch(Mutations.timelineDiffHours, payload);
}

function reloadDownloads(payload) {
  store.dispatch(Mutations.reloadDownloads, payload);
}

function globalMessage(payload) {
  store.dispatch(Mutations.globalMessage, payload);
}

function stopTimer(payload) {
  store.dispatch(Mutations.stopTimer, payload);
}

export {
  changedDeviceId,
  timelineDiffHours,
  reloadDownloads,
  globalMessage,
  stopTimer,
};
