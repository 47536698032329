import {
  bindable,
  computedFrom,
  observable
} from 'aurelia-framework';
import './cctv-channels-player.scss';

import { Store } from 'aurelia-store';
import * as Actions from '../cctv-module-store-manager/actions.js';


export class CctvChannelsPlayer {
  @bindable toggleStopBtn
  @bindable channel
  @bindable filters;
  @bindable mute;
  @bindable loadingMedia;
  @bindable playing;
  @bindable timelineCurrentDate;
  @bindable screenIsBig;
  @bindable selectedChannels;
  @bindable checkboxChange;
  @bindable onTimeUpdate;

  @observable waitingAlert;
  static inject = [Store];

  constructor(store) {
    this.actions = Actions;
    this.store = store;

    this.filters = null;
    this.mute = false;
    this.playing = false;
    this.player = null;
    this.height = 'calc(100vh - 24em)';

    this.screenIsSelected = 1;
    this.screenIsBig = true;

    this.checkboxChange = null;

    this.waitingAlert = '';
    this.timer = null;

    this.updateTime = this.updateTime.bind(this)
  }

  attached() {
    if (this.channel.id != 1) {
      this.height = 'calc(34vh - 4em)';
    } else {
      var element = document.getElementById('video-js-1');
      this.height = 'calc(100vh - 24em)';
      this.screenIsSelected = 1;
      this.screenIsBig = true;
      element.classList.add("isBigSize");
    }

    this.store.state.subscribe((state) => {
      if (state.timer) {
        clearTimeout(this.timer);
      }
    });
  }

  checkboxChangeChanged() {
    if (this.channel.id == this.screenIsSelected && this.screenIsBig) {
      this.toggleScreenDimensions(this.channel.id);
    }
  }

  @computedFrom('channel.selected')
  get availableChannels() {
    if (this.channel.id == this.screenIsSelected && !this.channel.selected) {
      this.toggleScreenDimensions(this.channel.id);
    }
    return this.channel.selected
  }

  updateTime(channel) {
    this.toggleStopBtn = true;
    this.loadingMedia = false;
    let firstSelected;
    if (!channel.el || !channel.selectedMedia) return;
    if (this.onTimeUpdate) {
      firstSelected = this.selectedChannels.find(channel => channel.selected && channel.selectedMedia)
      let x = document.getElementById('video-js-' + channel.id);
      x.classList.remove('video-js-' + channel.id + '-dimensions');
      if (firstSelected.camera == channel.camera) {
        this.onTimeUpdate(channel)
      }
    }
  }

  toggleScreenDimensions(id) {
    let element = document.getElementById('video-js-' + id);

    if (!this.screenIsBig) {
      this.height = 'calc(100vh - 24em)';
      element ? element.classList.add("isBigSize") : '';
      this.screenIsSelected = id;
      this.screenIsBig = true;

    } else {
      this.height = 'calc(34vh - 4em)';
      element ? element.classList.remove("isBigSize") : '';
      element ? element.classList.add("isMiniSize") : '';
      element ? element.style.height = 'calc(34vh - 4em)' : '';
      this.screenIsSelected = null;
      this.screenIsBig = false;
    }
  }

  onEnded(channel) {
    if (!channel.el) return;

    if (channel.hls) {
      channel.hls.detachMedia();
      channel.hls.destroy();
    } else {
      channel.el.node.pause();
      channel.el.node.setAttribute('src', '');
    }
    channel.selectedMedia = null;
  }

  onAbort(channel) {
    if (!channel.el) return;
  }

  onProgress(channel) {
    if (!channel.el) return;
  }

  waitingAlertChanged(newVal) {
    if (newVal) {
      this.timer = setTimeout(() => {
        this.waitingAlert = ""
      }, 6000);
    }
  }

  onWaiting() {
    this.toggleStopBtn = false;
    this.loadingMedia = true;

    this.waitingAlert = "";
    this.timer = setTimeout(() => {
      this.waitingAlert = "Hold tight! We're still waiting for the feed from the device"
    }, 10000);

  }

  onPlay() {
    this.playing = true;
    this.loadingMedia = false;
  }

  onPause() {
    this.playing = false;
    this.loadingMedia = false;
  }

  unbind() {
    if (this.channel.el) {
      this.channel.el.destroy();
    }
  }
}
