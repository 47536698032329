import store from './store';

// Mutations are merely dispatched actions, 
// the logic that makes changes to your state management store 
// is handled from within here

function changedDeviceId(state, payload) {
  const newState = Object.assign({}, state, { generatedDeviceId: payload });

  return newState;
}

function timelineDiffHours(state, payload) {
  const newState = Object.assign({}, state, { getTimelineDiffHours: payload });

  return newState;
}

function reloadDownloads(state, payload) {
  const newState = Object.assign({}, state, { getReloadDownloads: payload });

  return newState;
}

function globalMessage(state, payload) {
  const newState = Object.assign({}, state, { getGlobalMessage: payload });

  return newState;
}

function stopTimer(state, payload) {
  const newState = Object.assign({}, state, { timer: payload });

  return newState;
}

store.registerAction('changedDeviceId', changedDeviceId);
store.registerAction('timelineDiffHours', timelineDiffHours);
store.registerAction('reloadDownloads', reloadDownloads);
store.registerAction('globalMessage', globalMessage);
store.registerAction('stopTimer', stopTimer);

export {
  changedDeviceId,
  timelineDiffHours,
  reloadDownloads,
  globalMessage,
  stopTimer,
};
