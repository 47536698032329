import {
  bindable,
  computedFrom
} from "aurelia-framework";

import './manage-cards.scss';

export class ManageCards {

  @bindable visible;
  @bindable cards;
  @bindable enabledCards;

  @bindable onCardUpdate;

  constructor() {
    this.visible = false;
    this.loading = false;

    this.cards = [];
    this.enabledCards = [];

    this.toggleCard = this.toggleCard.bind(this)
  }

  @computedFrom('cards', 'enabledCards')
  get availableCards() {
    return this.cards.map(c => {
      c.status = (this.enabledCards.find(ec => ec.id == c.id)) ? true : false;
      return c;
    })
  }

  toggleCard(e, id, state) {
    if (this.onCardUpdate) {
      let period = this.defaultPeriod(id);
      this.onCardUpdate(e, id, state, period);
    }
  }

  defaultPeriod(id) {
    let period = null;
    switch (id) {
      case 'fleetUsage':
        period = 'today';
        break;
      case 'journeys':
      case 'mileage':
      case 'fuelCost':
        period = 'week';
        break;
    }

    return period;
  }

  closeManager() {
    this.visible = false;
  }
}
