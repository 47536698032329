import {
  computedFrom
} from 'aurelia-framework';
import dashboardService from 'services/api/dashboardService';
import {
  DateTimeUtils as DTU,
  ConvertUtils
} from '@fonix/web-utils';

import './mileage-card.scss';

export class MileageCard {

  constructor() {
    this.dashboardService = dashboardService;

    this.loading = false;
    this.card = {};
    this.getPeriodicLabels = null;
    this.period = null;

    this.options = {
      height: 220,
      showPoint: true,
      axisX: {
        showLabel: true,
        showGrid: false
      },
      axisY: {
        showLabel: false,
        showGrid: false
      },
      chartPadding: {
        left: -25,
        right: 0
      },
      plugins: [
        'tooltip'
      ]
    };

    this.responsiveOptions = [
      ['screen and (max-width: 600px)', {
        height: 220
      }],
      ['screen and (min-width: 601px)', {
        height: 230
      }],
      ['screen and (min-width: 993px)', {
        height: 250
      }],
      ['screen and (min-width: 1200px)', {
        height: 260
      }]
    ]

    this.periods = [{
        id: 'today',
        label: 'today'
      },
      {
        id: 'week',
        label: 'last_7_days'
      },
      {
        id: 'month',
        label: 'last_30_days'
      }
    ]
  }

  activate(data) {
    if (data.card && data.card.id) {
      this.card.id = data.card.id
    }
    this.period = (data.card && data.card.period) ? data.card.period : 'today';
    this.generateReport = (data.generateReport) ? data.generateReport.bind(this) : null;
    this.getPeriodicLabels = (data.getPeriodicLabels) ? data.getPeriodicLabels.bind(this) : null;
    this.onCardUpdate = (data.onCardUpdate) ? data.onCardUpdate.bind(this) : null;

    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.dashboardService.get(this.card.id, this.period).then(kpi => {
      this.card = kpi;
      this.loading = false;
    }).catch(err => {
      this.error = err.message
      this.loading = false;
    });
  }

  setActivePeriod(period) {
    if (period) {
      this.period = period;
      this.loadData();

      if (this.onCardUpdate) {
        this.onCardUpdate(null, this.card.id, true, period)
      }
    }
  }

  @computedFrom('period')
  get activePeriod() {
    return this.period;
  }

  @computedFrom('card')
  get adaptedData() {
    let chartData = {};
    chartData.series = []
    chartData.labels = []
    if (this.getPeriodicLabels) {
      chartData.labels = this.getPeriodicLabels(this.period);
    }

    if (this.card && this.card.data && this.card.data.length) {
      this.card.data.forEach(data => {
        chartData.series.push(data.values.map(value => {
          let seriesValue = {}
          let distance = ConvertUtils.convertUnit('distance', value, '0,0.0');
          seriesValue.value = value;
          seriesValue.meta = distance.unit ? distance.value + ' ' + distance.unit.name : distance.value + ' mi';
          return seriesValue;
        }))
      });
    }

    return chartData
  }

  viewReport() {
    if (this.generateReport) {
      var settings = {
        reportId: 1, //journey-summary
        preferences: {
          pagePerContext: false
        },
        customSettings: {},
        dates: {},
        contextType: 'assets',
        context: [-1],
        timeZone: DTU.detectTZ()
      }

      if (this.period) {
        switch (this.period) {
          case 'today':
            settings.dates = {
              from: DTU.today(),
              to: DTU.endOf(DTU.today())
            }
            break;
          case 'week':
            settings.dates = {
              from: DTU.startOf(DTU.subtract(DTU.today(), 7, 'days')),
              to: DTU.endOf(DTU.today())
            }
            break;
          case 'month':
            settings.dates = {
              from: DTU.startOf(DTU.subtract(DTU.today(), 30, 'days')),
              to: DTU.endOf(DTU.today())
            }
            break;
        }
      }

      this.generateReport(settings)
    }
  }
}
