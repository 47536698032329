import {
  observable,
  computedFrom
} from "aurelia-framework";
import {
  DateTimeUtils as DTU
} from '@fonix/web-utils';

import userService from 'services/api/userService';
import reportsService from 'services/api/reportsService';
import dashboardService from 'services/api/dashboardService';

import './dashboard.scss';

const DEFAULT_DASHBOARD_CARDS = [{
    id: 'fleetStatus',
    period: null
  },
  {
    id: 'journeys',
    period: 'week'
  },
  {
    id: 'mileage',
    period: 'week'
  }
];

export class Dashboard {

  @observable cards;
  @observable enabledCards;

  constructor() {
    this.userService = userService;
    this.reportsService = reportsService;
    this.dashboardService = dashboardService;

    this.mainActions = [{
      name: 'customise',
      action: this.toggleManageCards.bind(this)
    }]
    this.manageCards = false;
    this.loading = false;
    this.error = null;

    this.isGenerating = false;
    this.displayReport = null;
    this.selectedReport = null;
    this.activeDialogAction = null;

    this.cards = [];
    this.enabledCards = [];

    this.onCardUpdate = this.onCardUpdate.bind(this)
    this.generateReport = this.generateReport.bind(this);
  }

  attached() {
    this.loadCards();
  }

  loadCards() {
    this.loading = true;
    this.dashboardService.get().then(kpis => {
      this.cards = kpis.map(kpi => {
        kpi.icon = this.getCardIcon(kpi.id)
        return kpi;
      })

      this.loadEnabledCards();
      this.loading = false;
    }).catch(err => {
      this.error = err.message
      this.loading = false;
    });
  }

  loadEnabledCards() {
    if (this.userService.user && this.userService.user.preferences.dashboard) {
      this.enabledCards = this.userService.user.preferences.dashboard;
    } else {
      this.enabledCards = DEFAULT_DASHBOARD_CARDS
    }
  }

  toggleManageCards() {
    this.manageCards = !this.manageCards;
  }

  triggerMainAction(button) {
    if (button && button.action) {
      button.action();
    }
  }

  onCardUpdate(e, id, state, period) {
    if (state) {
      var enabledCard = this.enabledCards.find(c => c.id == id)
      if (enabledCard) {
        enabledCard.period = period;
      } else {
        this.enabledCards.push({
          id,
          period
        })
      }
    } else {
      let index = this.enabledCards.map(c => {
        if (c) return c.id;
      }).indexOf(id);

      if (index >= 0) this.enabledCards.splice(index, 1);
    }

    this.enabledCards = this.enabledCards.filter(c => {
      if (c) return c
    });

    this.updateUserPreferences();
  }

  getCardIcon(id) {
    let icon = '';
    switch (id) {
      case 'fleetStatus':
      case 'fleetUsage':
        icon = 'pie-chart';
        break;
      case 'journeys':
      case 'mileage':
        icon = 'line-chart';
        break;
      case 'fuelCost':
        icon = 'multiline-chart';
        break;
    }

    return icon;
  }

  getPeriodicLabels(period) {
    var periods = [];
    let today = new Date();
    switch (period) {
      case 'today':
        var interval = 2;
        var intervalCounter = 0;
        for (let hour = 0; hour < 24; hour++) {
          intervalCounter++
          if (intervalCounter == 1) {
            let hourString = hour < 10 ? '0' + hour : hour;
            periods.push(hourString + ':' + '00')
          } else {
            periods.push('')
          }
          if (intervalCounter >= interval) {
            intervalCounter = 0;
          }
        }
        break;
      case 'week':
        for (let days = 7; days > 0; days--) {
          periods.push(DTU.format(DTU.subtract(today, days, 'days'), 'ddd'));
        }
        periods.push(DTU.format(today, 'ddd'));
        break;
      case 'month':
        var interval = 7;
        var intervalCounter = 0;
        for (let days = 30; days > 0; days--) {
          intervalCounter++
          if (intervalCounter == 1) {
            periods.push(DTU.format(DTU.subtract(today, days, 'days'), 'Do'));
          } else {
            periods.push('')
          }
          if (intervalCounter >= interval) {
            intervalCounter = 0;
          }
        }
        break;
    }
    return periods;
  }

  bindableModel(card) {
    return {
      card: card,
      getPeriodicLabels: this.getPeriodicLabels.bind(this),
      generateReport: this.generateReport.bind(this),
      onCardUpdate: this.onCardUpdate.bind(this)
    }
  }

  updateUserPreferences() {
    this.userService.user.preferences.dashboard = this.enabledCards;
    this.userService.updateSelf(this.userService.user);
  }

  @computedFrom('cards', 'enabledCards')
  get availableCards() {
    return this.cards.filter(c => {
      if (this.enabledCards.find(ec => ec.id == c.id)) {
        return c;
      }
    }).map(c => {
      let enabledCard = this.enabledCards.find(ec => ec.id == c.id)
      c.period = enabledCard.period;
      return c;
    })
  }

  generateReport(settings) {
    let newWin = window.open('', '_blank');
    if (newWin) {
      newWin.document.body.innerHTML =
        '<div style="font-size:18px; font-family: Helvetica;">Preparing report...</div>';

      this.reportsService
        .createExecution(settings.reportId, settings)
        .then(exec => {
          let url = `${window.location.origin}/#/reports/${exec.id}`;
          newWin.location = url;
        });
    }
  }
}
