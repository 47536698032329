import {
  computedFrom
} from 'aurelia-framework';
import dashboardService from 'services/api/dashboardService';

export class FleetUsageCard {

  constructor() {
    this.dashboardService = dashboardService;

    this.loading = true;
    this.error = null;
    this.card = {};
    this.period = null;

    this.options = {
      chartPadding: 0,
      donut: true,
      donutWidth: '50%',
      donutSolid: true,
      startAngle: 270,
      showLabel: true,
      width: '100%',
      total: 200,
      ignoreEmptyValues: true,
      labelInterpolationFnc: function (value) {
        return value + '%';
      }
    };

    this.responsiveOptions = [
      ['screen and (max-width: 600px)', {
        donutWidth: '50%',
        height: 180
      }],
      ['screen and (min-width: 601px)', {
        donutWidth: '50%',
        height: 190
      }],
      ['screen and (min-width: 993px)', {
        donutWidth: 50,
        height: 210
      }],
      ['screen and (min-width: 1200px)', {
        donutWidth: 50,
        height: 220
      }]
    ]

    this.periods = [{
        id: 'today',
        label: 'today'
      },
      {
        id: 'week',
        label: 'last_7_days'
      },
      {
        id: 'month',
        label: 'last_30_days'
      }
    ]
  }

  activate(data) {
    if (data.card && data.card.id) {
      this.card.id = data.card.id
    }
    this.period = (data.card && data.card.period) ? data.card.period : 'today';
    this.onCardUpdate = (data.onCardUpdate) ? data.onCardUpdate.bind(this) : null;

    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.dashboardService.get(this.card.id, this.period).then(kpi => {
      this.card = kpi;
      this.loading = false;
    }).catch(err => {
      this.error = err.message
      this.loading = false;
    });
  }

  setActivePeriod(period) {
    if (period) {
      this.period = period;
      this.loadData();
      
      if (this.onCardUpdate) {
        this.onCardUpdate(null, this.card.id, true, period)
      }
    }
  }

  @computedFrom('period')
  get activePeriod() {
    return this.period;
  }


  @computedFrom('card')
  get adaptedData() {
    let chartData = {};
    chartData.series = []

    if (this.card && this.card.data && this.card.data.length) {
      chartData.series = this.card.data.map(d => {
        if (typeof d.value !== undefined) return d.value
      })
    }

    return chartData
  }
}
