import {
  bindable, observable
} from 'aurelia-framework';
import {
  AssetsService
} from 'services/api/assetsService';

import cctvService from 'services/api/cctvService';

import eventService, { EventsList } from 'services/eventService';

import { TranslationService } from 'services/translationService';

import * as Actions from '../cctv-module-store-manager/actions.js';

import { Store } from 'aurelia-store';

import './cctv-filters.scss'

export class CctvFilters {
  @bindable onFilterChanged;
  @bindable showDropdown;
  @bindable snapshot;

  @observable snapshotNotification;

  static inject = [Store, TranslationService];

  constructor(store, _TranslationService) {
    this.translations = _TranslationService;

    this.store = store;
    this.actions = Actions;
    this.assetsService = new AssetsService;
    this.cctvService = cctvService;

    this.selectedAsset = null;
    this.assets = [];
    this.snapshotNotification = null;

    this.onAssetSelect = this.onAssetSelect.bind(this);

    this.onDateFilterChanged = this.onDateFilterChanged.bind(this);

    eventService.subscribe(EventsList.SignalrbroadcastSnapshot, this.notificationAlert);

  }

  snapshotNotificationChanged() {
    this.snapshot = this.snapshotNotification;
  }

  attached() {
    this.loadAssets();
  }

  detached() {
    eventService.unsubscribe(EventsList.SignalrbroadcastSnapshot, this.notificationAlert);
    this.selectedAsset = null;
    this.showDropdown = false;
  }

  notificationAlert = item => {
    let alert = {
      prompt: false,
      message: '',
      button: {},
      mediaSettings: ''
    }

    // ignore messages without connectivity data
    if ( item.snapshot.isDeviceConnected == null ) {
      return;
    }

    // selected asset connectivity changed
    if ( this.selectedAsset ) {
      const assetId = this.selectedAsset.linkId ?? this.selectedAsset.id;

      if ( ( assetId == item.id ) && ( this.selectedAsset.isDeviceConnected != item.snapshot.isDeviceConnected ) ) {
        this.snapshotNotification = item.snapshot.isDeviceConnected;
  
        switch (item.snapshot.isDeviceConnected) {
          case true:
            alert.message = this.translations.getCap('device_back_online');
            this.actions.globalMessage(alert);
            break;
          case false:
            alert.message = this.translations.getCap('device_is_offline');
            this.actions.globalMessage(alert);
            break;
        }
    }
    }

    // update asset on list
    const foundIndex = this.assets.findIndex( x => x.id == item.id || x.linkId == item.id );
    if ( this.assets[foundIndex] 
      && this.assets[foundIndex].hasOwnProperty('isDeviceConnected')
      && ( this.assets[foundIndex].isDeviceConnected != item.snapshot.isDeviceConnected ) ) {
        this.assets[foundIndex].isDeviceConnected = item.snapshot.isDeviceConnected;
    }
  }

  loadAssets = () => {
    this.cctvService.getAllAssets().then(assets => {
      this.assets = assets;
    });
  }

  onAssetSelect(asset) {
    if (asset) {
      this.selectedAsset = asset;
      this.snapshotNotification = asset.isDeviceConnected;
    } else {
      this.selectedAsset = null;
      if (!this.snapshotNotification) {
        this.loadAssets();
      }
    }

    if (this.onFilterChanged) {
      this.onFilterChanged({
        asset: this.selectedAsset
      });
    }
  }

  onDateFilterChanged(dtfrom, dtto) {
    if (this.onFilterChanged && dtfrom && dtto) {
      this.onFilterChanged({
        dates: {
          dtfrom,
          dtto
        }
      });

      if (this.selectedAsset) {
        this.onFilterChanged({
          asset: this.selectedAsset
        });
      }
    }
  }
}
