import {
  bindable,
  computedFrom,
  inject,
  BindingEngine
} from 'aurelia-framework';

import moment from 'moment';
import {
  DateTimeUtils as DTU
} from '@fonix/web-utils';
import {
  DialogService
} from 'aurelia-dialog';

import cctvService from 'services/api/cctvService';
import { Store } from 'aurelia-store';
import * as Actions from '../cctv-module-store-manager/actions.js';

import {
  AdvancedSettingsDialog
} from '../advanced-settings-dialog/advanced-settings-dialog';
PLATFORM.moduleName('../advanced-settings-dialog/advanced-settings-dialog');
import {
  DownloadDialog
} from '../download-dialog/download-dialog';
PLATFORM.moduleName('../download-dialog/download-dialog');

import './cctv-actions.scss';


@inject(DialogService)
export class CctvActions {
  @bindable selectedChannels
  @bindable toggleStopBtn
  @bindable filters;
  @bindable mute;
  @bindable loadingMedia;
  @bindable playing;
  @bindable download;
  @bindable timelineCurrentDate;
  @bindable timelineDownloadRange;
  @bindable maxDownloadRangeDuration;
  @bindable playingSettingsMedia;

  @bindable startMedia;
  @bindable pauseMedia;
  @bindable seekMediaCurrentTime;
  @bindable rangedTime;

  static inject = [Store];
  constructor(dialogService, store) {
    this.actions = Actions;
    this.store = store;

    this.selectedChannels = [];
    this.filters = null;
    this.mute = false;
    this.playing = false;
    this.download = false;
    this.polFunction = false;
    this.toggleStopBtn = false;

    this.stopMedia = this.stopMedia.bind(this);
    this.playMedia = this.playMedia.bind(this);

    this.dialogService = dialogService;
    this.cctvService = cctvService;
    this.rangedTime = 20;
    this.customTime = null;

    this.defaultTime = {};

  }

  @computedFrom('selectedChannels')
  get availableMedia() {
    let availableMedia = this.selectedChannels.filter(channel => channel.selectedMedia)
    return (availableMedia.length) ? true : false;
  }

  @computedFrom('timelineDownloadRange.startDateTime')
  get timelineRangedStartTime() {
    if (this.timelineDownloadRange.startDateTime && this.download) {
      let startDate = moment(this.timelineDownloadRange.startDateTime).subtract((this.rangedTime / 2), 'seconds');
      return DTU.format(startDate, 'HH:mm:ss');
    }
  }

  @computedFrom('timelineDownloadRange.endDateTime')
  get timelineRangedEndTime() {
    if (this.timelineDownloadRange.endDateTime && this.download) {
      return DTU.format(this.timelineDownloadRange.endDateTime, 'HH:mm:ss');
    }
  }

  @computedFrom('timelineDownloadRange.startDateTime', 'timelineDownloadRange.endDateTime')
  get timelineRangedDuration() {
    if (this.timelineDownloadRange.startDateTime && this.timelineDownloadRange.endDateTime && this.download) {
      let duration = moment(this.timelineDownloadRange.endDateTime).diff(moment(this.timelineDownloadRange.startDateTime));
      return moment.utc(duration).format("ss");
    }
  }

  toggleDownload() {
    this.download = !this.download;
    this.customTime = null;
  }

  downloadChanged(value) {
    this.rangedTime = 20;

    if (this.playing && this.pauseMedia) {
      this.pauseMedia();
    }
    if (value) {
      if (DTU.isBefore(this.timelineCurrentDate, this.filters.dates.dtfrom)) {
        this.timelineCurrentDate = this.filters.dates.dtfrom;
      }
      if (DTU.isAfter(this.timelineCurrentDate, this.filters.dates.dtto)) {
        this.timelineCurrentDate = this.filters.dates.dtto;
      }
      let startDate = moment(this.timelineCurrentDate).subtract(10, 'seconds');
      let endDate = moment(this.timelineCurrentDate).add(10, 'seconds');

      this.timelineDownloadRange = {
        startDateTime: startDate,
        endDateTime: endDate
      }
    } else {
      this.timelineDownloadRange = {
        startDateTime: null,
        endDateTime: null
      }
    }

    const defaultTime = {
      startDateTime: this.timelineDownloadRange.startDateTime,
      endDateTime: this.timelineDownloadRange.endDateTime,
      rangedTime: moment(this.timelineDownloadRange.endDateTime).diff(moment(this.timelineDownloadRange.startDateTime), 'seconds')
    }
    sessionStorage.setItem('defaultTime', JSON.stringify(defaultTime));

  }

  changeRangeTimeValue() {
    this.rangedTime = document.getElementById('rangedSearch').value;

    let startDate = moment(this.timelineCurrentDate).subtract((this.rangedTime / 2), 'seconds');
    let endDate = moment(this.timelineCurrentDate).add((this.rangedTime / 2), 'seconds');

    this.timelineDownloadRange = {
      startDateTime: startDate,
      endDateTime: endDate
    }
  }

  openDialog() {
    this.timelineDownloadRange.rangedTime = this.rangedTime;
    this.dialogService
      .open({
        viewModel: AdvancedSettingsDialog,
        model: {
          timelineDownloadRange: this.timelineDownloadRange,
          timelineBorders: this.filters.dates,
        }
      })
      .whenClosed(result => {
        this.loading = false;
        if (!result.wasCancelled) {
          this.timelineDownloadRange = {
            startDateTime: result.output.startDateTime,
            endDateTime: result.output.endDateTime
          }
          this.rangedTime = result.output.rangedTime;
          this.setCustomTime(result.output.rangedTime);
        } else {
          if (result.output === 'reset') {
            let time = JSON.parse(sessionStorage.defaultTime);
            this.timelineDownloadRange = {
              startDateTime: time.startDateTime,
              endDateTime: time.endDateTime
            }
            this.rangedTime = time.rangedTime;
            this.customTime = null;
          }
        }
        this.timelineCurrentDate = DTU.add(this.timelineDownloadRange.startDateTime, (this.rangedTime / 2), 'seconds').toISOString();
      });
  }

  setCustomTime(totalSeconds) {
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);

    this.customTime = (hours != 0 ? hours + 'h' : '') + (minutes != 0 ? minutes + 'm' : '');
  }

  saveDownload() {
    this.timelineDownloadRange.rangedTime = this.rangedTime;
    this.dialogService
      .open({
        viewModel: DownloadDialog,
        model: {
          asset: this.filters.asset.deviceUUId,
          channels: this.selectedChannels,
          timelineDownloadRange: this.timelineDownloadRange,
          alert: this.customTime ? true : false
          // audio: !this.mute
        }
      })
      .whenClosed(result => {
        if (!result.wasCancelled) {
          this.loading = false;
          this.download = false;
        }
      });
  }

  playMedia() {
    if (this.startMedia) {
      this.startMedia();
    }
  }

  stopMedia() {
    if (this.pauseMedia) {
      this.actions.stopTimer(true);
      this.pauseMedia(true);
    }
  }

  toggleAudio() {
    this.mute = !this.mute;
    this.selectedChannels.forEach(channel => {
      if (channel.selectedMedia && channel.el) {
        channel.el.volume = (this.mute) ? 0 : 1;
      }
    });
  }

  jumpTimeline(seconds) {
    if (!this.filters.dates.dtfrom || (!this.selectedChannels || !this.selectedChannels.length)) return;
    if (this.playing && this.pauseMedia) {
      this.pauseMedia();
    }

    if (moment(DTU.add(this.timelineCurrentDate, seconds, 'seconds')).isBefore(this.filters.dates.dtfrom)) {
      this.timelineCurrentDate = this.filters.dates.dtfrom;
    } else if (moment(DTU.add(this.timelineCurrentDate, seconds, 'seconds')).isAfter(this.filters.dates.dtfrom) && moment(DTU.add(this.timelineCurrentDate, seconds, 'seconds')).isBefore(this.filters.dates.dtto)) {
      this.timelineCurrentDate = DTU.add(this.timelineCurrentDate, seconds, 'seconds')
    } else if (moment(DTU.add(this.timelineCurrentDate, seconds, 'seconds')).isAfter(this.filters.dates.dtto)) {
      this.timelineCurrentDate = this.filters.dates.dtto;
    }
  }

  skipNextTimeline() {
    if (!this.filters.dates.dtfrom || (!this.selectedChannels || !this.selectedChannels.length)) return;
    if (this.playing && this.pauseMedia) {
      this.pauseMedia();
    }
    var availableDates = [];
    this.selectedChannels.forEach(channel => {
      if (channel && channel.selected && channel.media) {
        var result = channel.media.filter(media => {
          return (moment(this.timelineCurrentDate).isBefore(media.startDateTime)) ? media : null;
        });
        if (result.length) {
          result.forEach(media => {
            availableDates.push(new Date(media.startDateTime));
          });
        }
      }
    })

    if (availableDates.length) {
      this.timelineCurrentDate = new Date(Math.min.apply(null, availableDates));
    } else {
      this.timelineCurrentDate = this.filters.dates.dtto
    }
  }

  skipPreviousTimeline() {
    if (!this.filters.dates.dtfrom || (!this.selectedChannels || !this.selectedChannels.length)) return;
    if (this.playing && this.pauseMedia) {
      this.pauseMedia();
    }
    var availableDates = [];
    this.selectedChannels.forEach(channel => {
      if (channel && channel.selected && channel.media) {
        var result = channel.media.filter(media => {
          return (moment(this.timelineCurrentDate).isAfter(media.startDateTime)) ? media : null;
        });
        if (result.length) {
          result.forEach(media => {
            availableDates.push(new Date(media.startDateTime));
          });
        }
      }
    })

    if (availableDates.length) {
      this.timelineCurrentDate = new Date(Math.max.apply(null, availableDates));
    } else {
      this.timelineCurrentDate = this.filters.dates.dtfrom
    }
  }

  unbind() {
    if (this.playing) {
      this.playingSettingsMedia.forEach(m => {
        this.cctvService.stopLiveStreaming(m.id, m.device, m.channel).catch(e => {
          console.log('Error stop streaming', e)
        });
      });
    }
  }
}
