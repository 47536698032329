import {
  inject,
  computedFrom
} from 'aurelia-framework';
import {
  DialogController
} from 'aurelia-dialog';
import cctvService from 'services/api/cctvService';
import * as Actions from '../cctv-module-store-manager/actions.js';
import { Store } from 'aurelia-store';
import { TranslationService } from 'services/translationService';

import './download-dialog.scss';

@inject(DialogController)
export class DownloadDialog {
  static inject = [Store, TranslationService];

  constructor(_DialogController, store, _TranslationService) {
    this.dialogController = _DialogController;
    this.translations = _TranslationService;

    this.channels = [];
    this.selectedChannels = [];
    this.timelineDownloadRange = {
      startDateTime: null,
      endDateTime: null,
      rangedTime: null
    };
    this.store = store;
    this.actions = Actions;
    this.asset = null;
    this.alert = false;
    this.audio = true;
    this.clipName = '';
    this.cctvService = cctvService;

  }

  activate(model) {
    if (model && model.channels) {
      model.channels.forEach(channel => {
        this.channels.push({
          id: channel.camera,
          name: channel.name,
          selected: channel.selected,
          chunks: channel.chunks
        })
      });
    }
    if (model && model.timelineDownloadRange) {
      this.timelineDownloadRange = model.timelineDownloadRange;
    }
    if (model && typeof model.audio !== "undefined") {
      this.audio = model.audio;
    }
    if (model && model.asset) {
      this.asset = model.asset;
    }
    if (model && model.alert) {
      this.alert = model.alert;
    }

  }

  @computedFrom('channels', 'clipName')
  get validateApply() {
    if (this.channels.find(channel => channel.selected) && this.timelineDownloadRange.startDateTime && this.timelineDownloadRange.endDateTime) {
      // if (this.channels.find(channel => channel.selected) && this.clipName.length && this.timelineDownloadRange.startDateTime && this.timelineDownloadRange.endDateTime) {
      return false;
    } else {
      return true;
    }
  }

  selectChannel(channel) {
    let selectedChannels = this.channels.filter(channel => channel.selected);
    if (!channel.selected && selectedChannels.length < 1) {
      channel.selected = !channel.selected;
      return
    }
  }

  onCancel() {
    this.dialogController.cancel()
  }

  onConfirm() {
    let data;
    let startDate = this.timelineDownloadRange.startDateTime;
    let endDate = this.timelineDownloadRange.endDateTime;
    this.channels.forEach(element => {
      if (element.selected) {

        const t1 = new Date( startDate );
        const t2 = new Date( endDate );

        // compute chunks
        const chunks = element.chunks.filter( 
          x => ( t1 > new Date( x.startDateTime ) && t1 < new Date( x.endDateTime ) )
               ||
               ( t2 > new Date( x.startDateTime ) && t1 < new Date( x.startDateTime ) )
        );

        const chunkData = [];

        // crappy logic
        if ( chunks.length > 1 ) {
          // multiple chunks
          // t1 - chunk1.end
          // chunk2..chunkN-1
          // chunkN.start - t2

          chunkData.push( {
            channel: element.id,
            device: this.asset,
            startDateTime: new Date( startDate ),
            endDateTime: new Date( chunks[0].endDateTime )
          } );

          if ( chunks.length > 2 ) {
            for ( var chunkIdx = 1; chunkIdx < chunks.length - 1; chunkIdx++ ) {
              chunkData.push( {
                channel: element.id,
                device: this.asset,
                startDateTime: new Date( chunks[chunkIdx].startDateTime ),
                endDateTime: new Date( chunks[chunkIdx].endDateTime )
              } );  
            }
          }

          chunkData.push( {
            channel: element.id,
            device: this.asset,
            startDateTime: new Date( chunks[chunks.length - 1].startDateTime ),
            endDateTime: new Date( endDate )
          } );
        }
        else {
          // single chunk
          chunkData.push( {
            channel: element.id,
            device: this.asset,
            startDateTime: new Date( startDate ),
            endDateTime: new Date( endDate )
          } );
        }

        // console.log( {
        //   t1: startDate.toISOString(),
        //   t2: endDate.toISOString()
        // } );
        // console.log( chunkData );

        const promises = [];
        chunkData.forEach( chunk => {
          promises.push( this.cctvService.getDeviceDownload( chunk ) );
        } );

        Promise.all( promises ).then( res => {
          const conflicted = res.find( x => x.response === 'conflict' );
          const failed = res.find( x => x.response === 'error' );

          if ( conflicted ) {
            this.actions.globalMessage( {
              message: this.translations.getCap( 'channel_is_busy' ),
              prompt: true,
              button: {
                label: 'Override',
                action: 'download'
              },
              mediaSettings: conflicted.media
            } );
          }
          else if ( failed ) {
            this.actions.globalMessage( {
              message: this.translations.getCap( 'service_unavailable' ),
              button: {
                label: '',
                action: ''
              }
            } );
          }
          else {
            this.actions.reloadDownloads( true );
          }
        } );

        this.dialogController.ok( {
          channel: element.id,
          device: this.asset,
          startDateTime: new Date(startDate),
          endDateTime: new Date(endDate)
        } );
      }
    });
  }
}
