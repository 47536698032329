import {
  inject, observable
} from 'aurelia-framework';
import {
  DialogController
} from 'aurelia-dialog';
import cctvService from 'services/api/cctvService';

import './share-download-dialog.scss';

@inject(DialogController)
export class ShareDownloadDialog {

  constructor(_DialogController) {
    this.cctvService = cctvService;
    this.dialogController = _DialogController;

    this.id = null;
    this.emails = null;
    this.loading = false;
    this.sent = false;
    this.success = false;
  }

  activate(model) {
    if (model.mediaId) {
      this.id = model.mediaId;
    }
  }

  onCancel() {
    this.dialogController.cancel('cancel');
  }

  onConfirm() {
    this.sent = true;
    this.loading = true;
    this.cctvService.shareDownload(this.id, this.emails.value)
      .then(res => {
        this.loading = false;
        this.success = true;
        setTimeout(() => {
          this.dialogController.ok('success');
        }, 1000);
      }).catch(e => {
        this.loading = false;
        this.emails = null;
      })
  }
}
