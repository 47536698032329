import {
  bindable,
  computedFrom,
  observable
} from 'aurelia-framework';
import moment from 'moment';
import {
  DateTimeUtils as DTU
} from '@fonix/web-utils';

import './cctv-timeline.scss';

import { Store } from 'aurelia-store';
import * as Actions from '../cctv-module-store-manager/actions.js';

export class CctvTimeline {
  @bindable channels;
  @bindable journeys;
  @bindable alerts;
  @bindable filters;
  @bindable selectedChannels

  @bindable timelineCurrentDate;

  @bindable playing;
  @bindable download;
  @bindable timelineDownloadRange;
  @bindable rangedTime;
  @bindable maxDownloadRangeDuration;

  @bindable startMedia;
  @bindable pauseMedia;
  @bindable seekMediaCurrentTime;
  @bindable screenIsBig;
  @bindable checkboxChange;
  @bindable customDates;

  @observable timelineRatio;
  @observable timelineCurrentPosition;

  static inject = [Store];

  constructor(store) {
    this.actions = Actions;
    this.store = store;

    this.channels = [];
    this.journeys = [];
    this.alerts = [];

    this.timelineCurrentDate = null;
    this.timelineCurrentTime = null;
    this.timelineRatio = 3;
    this.timelineWidth = 100 * this.timelineRatio;
    this.timelineCurrentPosition = 0;
    this.timelineCursor = null;
    this.downloadTimelineCursor = null;
    this.timelineCursorDrag = false;
    this.timelineDownloadRangeStartDrag = false;
    this.timelineDownloadRangeEndDrag = false;
    this.timelineDownloadRangeMidDrag = false;

    this.download = false;
    this.disableCheckbox = []
    this.currentPositionPercentage = null;
    this.timelineDownloadRange = {
      startDateTime: null,
      endDateTime: null
    }
  }

  timelineCurrentDateChanged(value) {
    let date = value;
    if (moment(date).isBefore(this.filters.dates.dtfrom)) {
      this.timelineCurrentTime = moment(this.filters.dates.dtfrom).format('HH:mm:ss');
      date = this.filters.dates.dtfrom;
      
    } else if (moment(date).isAfter(this.filters.dates.dtto)) {
      this.timelineCurrentTime = moment(this.filters.dates.dtto).format('HH:mm:ss');
      date = this.filters.dates.dtto;
      
    } else {
      this.timelineCurrentTime = moment(date).format('HH:mm:ss');
    }
    this.moveCursorTo(date);
    this.moveDownloadSelectorTo(date);
  }

  downloadChanged() {
    if (this.download) {
      this.increaseScale(true);
    } else {
      this.decreaseScale(true);
    }
  }

  increaseScale() {
    let ratio;
    ratio = ((this.timelineRatio + 0.2) <= 4) ? this.timelineRatio + 0.2 : 4;
    this.timelineRatio = parseFloat(ratio.toFixed(2));
    this.moveCursorTo(this.timelineCurrentDate);
    this.centerTimelineCursor();
  }

  decreaseScale(downloadView) {
    let ratio;
    if (downloadView) {
      if (this.customDates) {
        let duration = moment(this.customDates.dtto).diff((this.customDates.dtfrom), 'hour');
        duration < 6 ? this.timelineRatio = 1 : duration >= 6 && duration < 11 ? this.timelineRatio = 2 : this.timelineRatio = 3;
      } else {
        ratio = 3;
        this.timelineRatio = parseFloat(ratio.toFixed(2));
      }
    } else {
      ratio = ((this.timelineRatio - 0.2) >= 1.6) ? this.timelineRatio - 0.2 : 1.6;
      this.timelineRatio = parseFloat(ratio.toFixed(2));
    }
    this.moveCursorTo(this.timelineCurrentDate);
    this.centerTimelineCursor();

  }

  timelineRatioChanged() {
    this.timelineWidth = 100 * this.timelineRatio;
  }

  @computedFrom('timelineWidth')
  get timelineSize() {
    return this.timelineWidth + '%';
  }

  inputTimelineCurrentTime(value) {
    let date = moment(moment(this.timelineCurrentDate).format('YYYY-MM-DD') + ' ' + value).utc().toISOString();
    if (date) {
      this.timelineCurrentDate = moment(moment(this.timelineCurrentDate).format('YYYY-MM-DD') + ' ' + value).utc().toISOString();
    } else {
      this.timelineCurrentDate = moment(moment(this.timelineCurrentDate).format('YYYY-MM-DD') + ' ' + '00:00:00').utc().toISOString();
    }
  }

  moveDownloadSelectorTo(value) {
    this.timelineCurrentTime = DTU.format(value, 'HH:mm:ss');
    this.timelineDownloadRange.startDateTime = moment(value).subtract((this.rangedTime / 2), 'seconds').toISOString();
    this.timelineDownloadRange.endDateTime = moment(value).add((this.rangedTime / 2), 'seconds').toISOString();

  }

  moveCursorTo(timestamp) {
    if (!this.filters.dates || !this.filters.dates.dtfrom || !this.filters.dates.dtto) return;
    
    let totalSeconds = moment(this.filters.dates.dtto).diff(moment(this.filters.dates.dtfrom), 'seconds');
    let currentSeconds = moment(timestamp).diff(moment(this.filters.dates.dtfrom), 'seconds');
    let currentPositionPercentage = (currentSeconds * (this.timelineRatio * 100)) / totalSeconds;
    this.timelineCurrentPosition = (currentPositionPercentage >= 0) ? currentPositionPercentage : this.timelineCurrentPosition;
  }

  timelineCurrentPositionChanged() {
    if (this.playing && this.timelineCursorDrag && this.pauseMedia) {
      this.pauseMedia();
    }
    this.changeSelectedContent();
  }

  @computedFrom('timelineCurrentPosition')
  get cursorPosition() {
    return this.timelineCurrentPosition + '%';
  }

  centerTimelineCursor() {
    if (!this.filters.dates || !this.filters.dates.dtfrom || !this.filters.dates.dtto || !this.selectedChannels.length || this.timelineCursorDrag) return;

    let cursorPosition = this.timelineElement.parentElement.clientWidth * (this.timelineCurrentPosition / 100);
    let scroll = cursorPosition - (this.timelineElement.parentElement.clientWidth / 2);
    this.timelineElement.parentElement.scrollTo({
      left: scroll
    })
  }

  adjustTimelineCursor() {
    if (!this.filters || !this.filters.dates || !this.filters.dates.dtfrom || !this.filters.dates.dtto) return;
    if (!this.selectedChannels || !this.selectedChannels.length) {
      this.timelineCurrentDate = this.filters.dates.dtfrom;
    } else {
      let availableMedia = this.selectedChannels.find(c => c.selectedMedia);

      if (!availableMedia) {
        var availableDates = [];
        this.selectedChannels.forEach(channel => {
          if (channel && channel.selected && channel.media) {
            var result = channel.media.filter(media => {
              return (moment(this.timelineCurrentDate).isAfter(media.startDateTime) || moment(this.timelineCurrentDate).isBefore(media.startDateTime)) ? media : null;
            });
            if (result.length) {
              result.forEach(media => {
                availableDates.push(new Date(media.startDateTime));
              });
            }
          }
        })

        this.timelineCurrentDate = new Date(Math.min.apply(null, availableDates)) || new Date(Math.max.apply(null, availableDates)) || this.filters.dates.dtfrom;
      }
    }
  }

  scrollTimeline(event) {
    this.timelineElement.parentElement.scrollLeft += (event.deltaY * 5);
    event.preventDefault();
  }

  @computedFrom('filters.dates', 'filters.dates.dtto', 'filters.dates.dtfrom')
  get timelineRangedTime() {
    if (this.filters && this.filters.dates && this.filters.dates.dtfrom && this.filters.dates.dtto) {
      var range = [];
      var counter = 1;
      for (let hour = moment(this.filters.dates.dtfrom).format("HH"); hour <= moment(this.filters.dates.dtto).format("HH"); hour++) {
        range.push({
          type: 'hour',
          value: hour,
          position: counter
        })
        counter++;
        if (counter > 3) {
          counter = 1;
        }
      }
      return range
    }
  }

  timelineItemStartPosition(itemStartTime, checkTitle) {
    let totalSeconds = moment(this.filters.dates.dtto).diff(moment(this.filters.dates.dtfrom), 'seconds');
    let currentSeconds = moment(itemStartTime).diff(moment(this.filters.dates.dtfrom), 'seconds');
    let currentPositionPercentage = (currentSeconds * 100) / totalSeconds;
    if (checkTitle) {
      return currentPositionPercentage;
    } else {
      return currentPositionPercentage + '%';
    }
  }

  timelineItemWidth(itemStartTime, itemEndTime = null, dataText) {
    let totalSeconds = moment(this.filters.dates.dtto).diff(moment(this.filters.dates.dtfrom), 'seconds');
    let currentSeconds;
    if (!itemEndTime) {
      currentSeconds = (moment(itemEndTime || this.filters.dates.dtto, 'seconds'));
    } else {
      currentSeconds = (moment(itemEndTime || DTU.today()).diff(moment(itemStartTime), 'seconds'));
    }
    let currentPositionPercentage = (currentSeconds * 100) / totalSeconds;
    dataText ? this.currentPositionPercentage = currentPositionPercentage : null;
    return currentPositionPercentage + '%';
  }

  dragCursorsInTimeline(event) {
    if (this.timelineCursorDrag) {
      this.changeCursorInTimeline(event);
    } else if (this.timelineDownloadRangeStartDrag) {
      this.changeStartRangeInTimeline(event);
    } else if (this.timelineDownloadRangeEndDrag) {
      this.changeEndRangeInTimeline(event);
    } else if (this.timelineDownloadRangeMidDrag) {
      this.changeMidRangeInTimeline(event);
    }
  }

  changeCursorInTimeline(event, forceDateTime = null) {
    if (forceDateTime) {
      event.stopPropagation(event)
    }
    if (!this.filters.asset) return;
    if (this.playing && this.pauseMedia) {
      this.pauseMedia();
    }

    if (forceDateTime) {
      this.timelineCurrentDate = forceDateTime;
    } else {
      let offset = this.timelineElement.getClientRects()[0];
      let currentPosition = (event.clientX - offset.left) / (this.timelineRatio * 100);
      let currentPositionPercentage = (currentPosition * (this.timelineRatio * 100)) / this.timelineElement.clientWidth;
      let totalSeconds = moment(this.filters.dates.dtto).diff(moment(this.filters.dates.dtfrom), 'seconds');
      this.timelineCurrentDate = DTU.add(this.filters.dates.dtfrom, (totalSeconds * currentPositionPercentage), 'seconds');
    }
  }

  changeMidRangeInTimeline(event) {
    let offset = this.timelineElement.getClientRects()[0];
    let currentPosition = (event.clientX - offset.left) / (this.timelineRatio * 100);
    let currentPositionPercentage = (currentPosition * (this.timelineRatio * 100)) / this.timelineElement.clientWidth;
    let totalSeconds = moment(this.filters.dates.dtto).diff(moment(this.filters.dates.dtfrom), 'seconds');
    this.timelineDownloadRange.startDateTime = DTU.add(this.filters.dates.dtfrom, (totalSeconds * currentPositionPercentage), 'seconds');
    this.timelineDownloadRange.endDateTime = DTU.add(this.filters.dates.dtto, (totalSeconds * currentPositionPercentage), 'seconds');

    let downloadCursor = DTU.add(this.timelineDownloadRange.startDateTime, (this.rangedTime / 2), 'seconds').toISOString();
    this.timelineCurrentDate = downloadCursor;

    this.validateRangeDuration('mid');
  }

  validateRangeDuration(marker) {
    let duration = moment(this.timelineDownloadRange.endDateTime).diff(moment(this.timelineDownloadRange.startDateTime), 'seconds');
    switch (marker) {
      case 'start':
        if (duration <= 0) {
          this.timelineDownloadRange.endDateTime = DTU.add(this.timelineDownloadRange.startDateTime, 1, 'seconds');
        } else if (duration > this.maxDownloadRangeDuration) {
          this.timelineDownloadRange.endDateTime = DTU.add(this.timelineDownloadRange.startDateTime, this.maxDownloadRangeDuration, 'seconds');
        }
        break;
      case 'end':
        if (duration <= 0) {
          this.timelineDownloadRange.startDateTime = DTU.subtract(this.timelineDownloadRange.endDateTime, 1, 'seconds');
        } else if (duration > this.maxDownloadRangeDuration) {
          this.timelineDownloadRange.startDateTime = DTU.subtract(this.timelineDownloadRange.endDateTime, this.maxDownloadRangeDuration, 'seconds');
        }
        break;
      case 'mid':
        if (duration <= 0) {
          this.timelineDownloadRange.endDateTime = DTU.add(this.timelineDownloadRange.startDateTime, 1, 'seconds');
          this.timelineDownloadRange.startDateTime = DTU.subtract(this.timelineDownloadRange.endDateTime, 1, 'seconds');
        } else if (duration > this.maxDownloadRangeDuration) {
          this.timelineDownloadRange.endDateTime = DTU.add(this.timelineDownloadRange.startDateTime, this.maxDownloadRangeDuration, 'seconds');
          this.timelineDownloadRange.startDateTime = DTU.subtract(this.timelineDownloadRange.endDateTime, this.maxDownloadRangeDuration, 'seconds');
        }
        break;
    }
  }

  @computedFrom('timelineDownloadRange.startDateTime')
  get downloadStartRangedSize() {
    if (this.timelineDownloadRange.startDateTime && this.filters.dates.dtfrom && this.filters.dates.dtto) {
      let totalSeconds = moment(this.filters.dates.dtto).diff(moment(this.filters.dates.dtfrom), 'seconds');
      let currentSeconds = (moment(this.timelineDownloadRange.startDateTime).diff(moment(this.filters.dates.dtfrom), 'seconds'));
      let currentPositionPercentage = (currentSeconds * 100) / totalSeconds;
      return currentPositionPercentage + '%';
    }
  }

  @computedFrom('timelineDownloadRange.endDateTime')
  get downloadEndRangedSize() {
    if (this.timelineDownloadRange.endDateTime && this.filters.dates.dtfrom && this.filters.dates.dtto) {
      let totalSeconds = moment(this.filters.dates.dtto).diff(moment(this.filters.dates.dtfrom), 'seconds');
      let currentSeconds = (moment(this.filters.dates.dtto).diff(moment(this.timelineDownloadRange.endDateTime), 'seconds'));
      let currentPositionPercentage = (currentSeconds * 100) / totalSeconds;
      return currentPositionPercentage + '%';
    }
  }

  stopDraggableEvents(event) {
    this.timelineCursorDrag = false;
    this.timelineDownloadRangeStartDrag = false;
    this.timelineDownloadRangeEndDrag = false;
    this.timelineDownloadRangeMidDrag = false;
  }

  channelsChanged(newValue, oldValue) {
    if (this.channels && this.channels.length && !oldValue) {
      this.selectedChannels = this.channels;
      this.selectChannel(this.channels[0], true);
      this.filters.dates = this.customDates;
      if (!moment(this.timelineCurrentDate).isValid()) {
        this.timelineCurrentDate = this.filters.dates.dtfrom;
      }

      let duration = moment(this.filters.dates.dtto).diff((this.filters.dates.dtfrom), 'hour');
      duration < 6 ? this.timelineRatio = 1 : duration >= 6 && duration < 11 ? this.timelineRatio = 2 : this.timelineRatio = 3;
    }
    this.adjustTimelineCursor();
  }

  selectChannel(channel, manual) {
    if (!manual) {
      this.checkboxChange = !this.checkboxChange;
    }

    if (this.playing && this.pauseMedia) {
      this.pauseMedia();
    }

    if (manual) {
      let selectedChannel = this.selectedChannels.find(i => (i.id === channel.id))
      if (selectedChannel) {
        selectedChannel.selected = (selectedChannel.selected) ? false : true;
      }
    }

    if (!channel.selected && this.selectedChannels.filter(c => (c.selected)).length <= 0) {
      channel.selected = !channel.selected;
    }

    this.selectedChannels = this.selectedChannels.filter(c => c);
    this.disableCheckbox = this.selectedChannels.filter(s => s.selected === true);

    this.changeSelectedContent();
  }

  changeSelectedContent() {
    if (!this.filters || !this.filters.dates || !this.filters.dates.dtfrom || !this.filters.dates.dtto || (!this.selectedChannels || !this.selectedChannels.length)) return;
    this.selectedChannels.forEach(channel => {
      var media = null
      if (channel && channel.selected && channel.media) {
        media = channel.media.find(media => {
          return (moment(this.timelineCurrentDate).isBetween(media.startDateTime, media.endDateTime, null, '[]')) ? media : null;
        })
      }

      this.selectedChannels = this.selectedChannels.map(c => {
        if (c.id == channel.id) {
          c.selectedMedia = (media) ? media : null;
        }
        return c
      });

    });

    if (!this.selectedChannels.filter(c => c.selectedMedia).length && this.pauseMedia) {
      this.pauseMedia();
    }

    if (this.playing && this.startMedia) {
      this.startMedia()
    }
  }
}
